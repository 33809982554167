// 語系下拉選單
$(function () {
  $('.header_lang_switch_btn').on('click', function() {
    $('.header_lang_switch_list').toggleClass('show');
    $(this).toggleClass('show');
  });
  
  $('.header_lang_switch_list').find('.lang_switch').on('click', function() {
    $('.header_lang_switch_list').removeClass('show');
    $('.header_lang_switch_btn').removeClass('show');
  });
  
  $('#mobileMenuBtn').on('click', function () {
    $(this).toggleClass('active');
    $('.header_menu').toggleClass('active');
  });

  $('.header_menu_item.group_link').on('click', function() {
    $(this).toggleClass('group_active');
  })
});

$(function () {
  $('.qa_menu_opt').on('click', function () {
    $(this,'.qa_menu_items').toggleClass('active');
    $('.qa_menu_items').slideToggle("fast");
  });

  $('[data-qa-menu]').on('click', function () {
    const id = $(this).attr('data-qa-menu');
    const name = $(this).attr('data-menu-name');
    $('.qa_menu_opt').text(name);
    $('.qa_menu_opt, .qa_menu_items').removeClass('active');
    $(this).addClass('active');
    $(this).siblings().removeClass('active');
    $('[data-qa-content]').siblings().removeClass('active');
    $('[data-qa-content="' + id + '"]').addClass('active');
    if ($(window).width() <= 1000) {
      $('.qa_menu_items').slideToggle("fast");
    }
  });
  $('.qa_menu_item').first().trigger('click');

  if ($(window).width() < 1000) {
    $('.qa_menu_items').css('display', 'none');
  }

  $('.qa_accordion').find('.qa_accordion_header').on( 'click', function() {
    $(this).toggleClass('active');
    $(this).parents('.qa_accordion').toggleClass('active');
    $(this).parents('.qa_accordion').find('.accordion_content').slideToggle("fast");
  });
  $(window).resize(function () {
    if ($(window).width() > 1000) {
      $('.qa_menu_items').css('display', 'block');
    }else {
      $('.qa_menu_items').css('display', 'none');
    }
  });
});


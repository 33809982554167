$(function () {
  $('#scrollTop').on('click', function () {
    $('html, body').animate({ scrollTop: 0 }, 500, 'swing');
  });
  $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').on('click', function (e) {
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      let headerHeight = $('.header').height();
      let scrollH = headerHeight + 32;
      if ($(window).width() > 768) {
        scrollH = headerHeight
      };
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top - (scrollH)
        }, 900, function () {

          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) {
            return false;
          } else {
            $target.attr('tabindex', '-1');
            $target.focus();
          };
        });
      }
    }
  });
  $('.design_certification_slider.owl-carousel').owlCarousel({
    center: true,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    autoplay: true,
    nav: false,
    items: 7,
    onInitialized: coverFlowEfx,
    onTranslate: coverFlowEfx,
  });

  function coverFlowEfx(e) {
    idx = e.item.index;
    $('.owl-item.big').removeClass('big');
    $('.owl-item.medium').removeClass('medium');
    $('.owl-item.mdright').removeClass('mdright');
    $('.owl-item.mdleft').removeClass('mdleft');
    $('.owl-item.smallRight').removeClass('smallRight');
    $('.owl-item.smallLeft').removeClass('smallLeft');
    $('.owl-item.xsRight').removeClass('xsRight');
    $('.owl-item.xsLeft').removeClass('xsLeft');
    $('.owl-item').eq(idx).addClass('big');
    $('.owl-item').eq(idx + 1).addClass('medium mdright');
    $('.owl-item').eq(idx - 1).addClass('medium mdleft');
    $('.owl-item').eq(idx + 2).addClass('smallRight');
    $('.owl-item').eq(idx - 2).addClass('smallLeft');
    $('.owl-item').eq(idx + 3).addClass('xsRight');
    $('.owl-item').eq(idx - 3).addClass('xsLeft');
  }

  // filter 選單
  $('[data-filter]').on('click', function() {
    const name = $(this).attr('data-filter');
    const menu = $('[data-filter-menu=' + name+']');
    $(this).toggleClass('active');
    menu.toggleClass('active');
    menu.slideToggle("fast");
  });

  // editor table responsive
  $('.custom_editor').find('table').wrap('<div class="table_responsive"></div>')
});

$(window).scroll(function () {
  var window_scrollTop = $(window).scrollTop();
  if (window_scrollTop >= 300) {
    $('#scrollTop').addClass('show');
  } else {
    $('#scrollTop').removeClass('show');
  };
  $('.company_history_slider.owl-carousel').owlCarousel({
    items: 3,
    center: true,
    loop: true,
    slideSpeed: 6000,
    autoplay: false,
    nav: true,
    dots: false,
    navText: [
      "<span class='icon-prev'></span>",
      "<span class='icon-next'></span>"
    ],
    responsive: {
      0: {
        items: 2,
        margin: 10,
      },
      800: {
        margin: 20,
      },
      1440: {
        margin: 30,
      },
    },
  });
});

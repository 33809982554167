$(function () {
  $('.index_main_banner .owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    slideSpeed: 6000,
    autoplay: true,
    nav: false,
    dots: true,
  });
});

$(window).scroll(numScroll);
var numViewed = false;

function isScrolledIntoView(elem) {
  let docViewTop = $(window).scrollTop();
  let docViewBottom = docViewTop + $(window).height();

  let elemTop = $(elem).offset().top;
  let elemBottom = elemTop + $(elem).height();

  return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

function numScroll() {
  const aboutUs = $('.index_about_us').length;
  if (aboutUs > 0) {
    if (isScrolledIntoView($(".index_about_items")) && !numViewed) {
      numViewed = true;
      $('.item_number').each(function () {
        $(this).prop('Counter', 0).animate({
          Counter: $(this).text()
        }, {
          duration: 3500,
          easing: 'swing',
          step: function (now) {
            $(this).text(Math.ceil(now));
          }
        });
      });
    }
  }
}
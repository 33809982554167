$(function () {
  $('.prod_slider .owl-carousel').owlCarousel({
    items: 1,
    // loop: true,
    slideSpeed: 6000,
    autoplay: false,
    nav: false,
    dots: false,
    thumbs: true,
    thumbImage: false,
    thumbsPrerendered: true,
    thumbContainerClass: 'owl-thumbs',
    thumbItemClass: 'owl-thumb-item'
  });

  let windowWidth = $(window).width();
  let thumbHeight = $('.owl-thumb-item').outerHeight();
  let thumbLength = $('.owl-thumb-item').length;

  if (thumbLength <= 1) {
    $('.owl-thumb-nav').addClass('hide');
  } else {
    $('.owl-thumb-nav').removeClass('hide');
  }

  thumbScroll();

  var navNext = document.getElementById('thumb-nav-next')
  if (navNext) {
    navNext.addEventListener('click', function () {
      $('.prod_slider .owl-carousel').trigger('next.owl.carousel');
      if (windowWidth >= 678) {
        $('.owl-thumbs').animate({ scrollTop: '+=' + thumbHeight }, 300);
      } else {
        $('.owl-thumbs').animate({ scrollLeft: '+=' + thumbHeight }, 300);
      }
    });
  }

  var navPrev = document.getElementById('thumb-nav-prev')
  if (navPrev) {
    document.getElementById('thumb-nav-prev').addEventListener('click', function () {
      $('.prod_slider .owl-carousel').trigger('prev.owl.carousel');
      if (windowWidth >= 678) {
        $('.owl-thumbs').animate({ scrollTop: '-=' + thumbHeight }, 300);
      } else {
        $('.owl-thumbs').animate({ scrollLeft: '-=' + thumbHeight }, 300);
      }
    });
  }

  $('.owl-thumbs-outer').on('click', function() {
    thumbScroll();
  });

  var prodCarousel = document.getElementById('prodCarousel')
  var observer = new MutationObserver(function (event) {
    const thumbsRect = $('.owl-thumbs')[0].getBoundingClientRect();
    const thumbActRect = $('.owl-thumb-item.active')[0].getBoundingClientRect();
    setTimeout(() => {
      if (windowWidth >= 678) {
        if ((thumbActRect.y - thumbsRect.y) > thumbActRect.width*2) {
          $('.owl-thumbs').animate({ scrollTop: '+=' + thumbActRect.width }, 300);
        } else if ((thumbActRect.y - thumbsRect.y) < thumbActRect.width * 2) {
          $('.owl-thumbs').animate({ scrollTop: '-=' + thumbActRect.width }, 300);
        }
      }
      thumbScroll();
    }, 500);
  });

  if (prodCarousel) {
    prodCarousel.addEventListener('touchend', function (e) {
      const thumbsRect = $('.owl-thumbs')[0].getBoundingClientRect();
      const thumbActRect = $('.owl-thumb-item.active')[0].getBoundingClientRect();
      setTimeout(() => {
        if ((thumbActRect.x - thumbsRect.x) > thumbActRect.width * 1.5) {
          $('.owl-thumbs').animate({ scrollLeft: '+=' + thumbActRect.width }, 300);
        } else if ((thumbActRect.x - thumbsRect.x) < thumbActRect.width * 1.5) {
          $('.owl-thumbs').animate({ scrollLeft: '-=' + thumbActRect.width }, 300);
        }
        thumbScroll();
      }, 500);
    })
    observer.observe(prodCarousel, {
      attributes: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false
    })
  }


  function thumbScroll() {
    if ($('.owl-thumb-item').first().hasClass('active')){
      $('.owl-thumb-prev').addClass('hide');
    }else {
      $('.owl-thumb-prev').removeClass('hide');
    }
    if ($('.owl-thumb-item').last().hasClass('active')){
      $('.owl-thumb-next').addClass('hide');
    }else {
      $('.owl-thumb-next').removeClass('hide');
    }
  };

  $('.owl-carousel.prod_industry_items').owlCarousel({
    items: 3,
    slideSpeed: 6000,
    autoplay: false,
    nav: true,
    dots: false,
    navText: [
      "<span class='icon-prev'></span>",
      "<span class='icon-next'></span>"
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      800: {
        items: 3,
      },
    },
  });
});

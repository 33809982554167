// CSS Style
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'sweetalert2/dist/sweetalert2.css';
import 'styles/public'

import 'core-js';
import Rails from "@rails/ujs"
import WOW from 'wow.js';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Cookies from 'js-cookie';
import 'owl.carousel';
import 'owl.carousel2.thumbs';
import 'public/base';
import 'public/header'
import 'public/index'
import 'public/qa'
import 'public/product'

Rails.start();

window.jQuery = $;
window.$ = $;
window.Swal = Swal;
window.Cookies = Cookies;

$(function () {
  isIE();
  var wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 0,
    mobile: true,
    live: false,
    scrollContainer: null
  });
  wow.init();
});

function isIE() { //ie?
  if (!!window.ActiveXObject || "ActiveXObject" in window) {
    $("body").addClass('isIE');
  } else {
    $("body").removeClass('isIE');
  }
}
